dmx.Component('summernote', {

  initialData: {
    disabled: false,
    value: '',
  },

  attributes: {
    value: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    config: {
      type: Object,
      default: {},
    },

    height: {
      type: Number,
      default: null,
    },

    minHeight: {
      type: Number,
      default: null,
    },

    maxHeight: {
      type: Number,
      default: null,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    lang: {
      type: String,
      default: 'en-US',
    },

    airMode: {
      type: Boolean,
      default: false,
    },

    toolbar: {
      type: Array,
      default: null, // use default from summernote (https://summernote.org/deep-dive/#custom-toolbar-popover)
    },

    toolbarImage: {
      type: Array,
      default: null, // use default from summernote (popover:{image:[...]})
    },

    toolbarLink: {
      type: Array,
      default: null, // use default from summernote (popover:{link:[...]})
    },

    toolbarTable: {
      type: Array,
      default: null, // use default from summernote (popover:{table:[...]})
    },

    toolbarAir: {
      type: Array,
      default: null, // use default from summernote (popover:{air:[...]})
    },

    blockquoteBreakingLevel: {
      // https://summernote.org/deep-dive/#blockquote-breaking-level
      type: Number,
      default: 2,
    },

    styleTags: {
      // https://summernote.org/deep-dive/#custom-styles
      type: Array,
      default: null,
    },

    fontNames: {
      // https://summernote.org/deep-dive/#custom-fontnames
      type: Array,
      default: null,
    },

    fontNamesIgnoreCheck: {
      // https://summernote.org/deep-dive/#custom-fontnames
      type: Array,
      default: null,
    },

    fontSizeUnits: {
      // https://summernote.org/deep-dive/#custom-font-size-units
      type: Array,
      default: null,
    },

    lineHeights: {
      // https://summernote.org/deep-dive/#custom-line-heights
      type: Array,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    dialogsInBody: {
      type: Boolean,
      default: false,
    },

    dialogsFade: {
      type: Boolean,
      default: false,
    },

    disableDrop: {
      type: Boolean,
      default: false,
    },

    disableShortcuts: {
      type: Boolean,
      default: false,
    },

    disableTab: {
      type: Boolean,
      default: false,
    },

    disableSpellcheck: {
      type: Boolean,
      default: false,
    },

    disableGrammar: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    disable () {
      this._disable();
    },

    empty () {
      this._empty();
    },

    enable () {
      this._enable();
    },

    insertText (str) {
      this._innerText(str);
    },

    pasteHTML (str) {
      this._pasteHTML(str);
    },

    redo () {
      this._redo();
    },

    reset () {
      this._reset();
    },

    setValue (value) {
      this._setValue(value);
    },

    undo () {
      this._undo();
    },

    status (message) {
      this._editor.layoutInfo.editor.find('.note-status-output').html(message);
    },

    info (message) {
      this._editor.layoutInfo.editor
        .find('.note-status-output')
        .html('<div class="alert alert-info">' + message + '</div>');
    },

    success (message) {
      this._editor.layoutInfo.editor
        .find('.note-status-output')
        .html('<div class="alert alert-success">' + message + '</div>');
    },

    warning (message) {
      this._editor.layoutInfo.editor
        .find('.note-status-output')
        .html('<div class="alert alert-warning">' + message + '</div>');
    },

    danger (message) {
      this._editor.layoutInfo.editor
        .find('.note-status-output')
        .html('<div class="alert alert-danger">' + message + '</div>');
    },

    invoke (action, arg) {
      this._editor.invoke(action, arg);
    },
  },

  events: {
    blur: Event,
    change: Event,
    changed: Event,
    enter: Event,
    focus: Event,
    init: Event,
    input: Event,
    updated: Event,
    buttonclick: Event,
    mediadelete: Event,
  },

  init (node) {
    this._blurHandler = this._blurHandler.bind(this);
    this._changeHandler = this._changeHandler.bind(this);
    this._enterHandler = this._enterHandler.bind(this);
    this._focusHandler = this._focusHandler.bind(this);
    this._initHandler = this._initHandler.bind(this);
    this._mediaDeleteHandler = this._mediaDeleteHandler.bind(this);

    this._config = { popover: $.summernote.options.popover };

    this._callbacks = {
      callbacks: {
        onBlur: this._blurHandler,
        onChange: this._changeHandler,
        onEnter: this._enterHandler,
        onFocus: this._focusHandler,
        onInit: this._initHandler,
        onMediaDelete: this._mediaDeleteHandler,
      },
    };
  },

  render (node) {
    if (this.props.value) {
      if (node.tagName == 'TEXTAREA') {
        node.value = this.props.value;
      } else {
        node.innerHTML = this.props.value;
      }
    }

    this._initEditor();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('value')) {
      this._setValue(this.props.value);
    }

    if (updatedProps.has('disabled')) {
      this._editor.invoke(this.props.disabled ? 'disable' : 'enable');
    }
  },

  destroy () {
    this._editor.destroy();
  },

  _initEditor () {
    const config = dmx.clone(this._config);

    config.height = this.props.height;
    config.minHeight = this.props.minHeight;
    config.maxHeight = this.props.maxHeight;
    config.focus = this.props.autofocus;
    config.lang = this.props.lang;
    config.airMode = this.props.airMode;
    config.placeholder = this.props.placeholder;
    config.dialogsInBody = this.props.dialogsInBody;
    config.dialogsFade = this.props.dialogsFade;
    config.disableDragAndDrop = this.props.disableDrop;
    config.shortcuts = !this.props.disableShortcuts;
    config.tabDisable = this.props.disableTab;
    config.spellCheck = !this.props.disableSpellcheck;
    config.disableGrammar = this.props.disableGrammar;
    config.blockquoteBreakingLevel = this.props.blockquoteBreakingLevel;

    if (Array.isArray(this.props.toolbar)) {
      // extra filter for empty group generated in Wappler
      config.toolbar = this.props.toolbar.filter(function (group) {
        return group.length;
      });
    }

    if (Array.isArray(this.props.toolbarImage)) {
      config.popover = config.popover || {};
      config.popover.image = this.props.toolbarImage.filter(function (group) {
        return group.length;
      });
    }

    if (Array.isArray(this.props.toolbarLink)) {
      config.popover = config.popover || {};
      config.popover.link = this.props.toolbarLink.filter(function (group) {
        return group.length;
      });
    }

    if (Array.isArray(this.props.toolbarTable)) {
      config.popover = config.popover || {};
      config.popover.table = this.props.toolbarTable.filter(function (group) {
        return group.length;
      });
    }

    if (Array.isArray(this.props.toolbarAir)) {
      config.popover = config.popover || {};
      config.popover.air = this.props.toolbarAir.filter(function (group) {
        return group.length;
      });
    }

    if (Array.isArray(this.props.styleTags)) {
      config.styleTags = this.props.styleTags;
    }

    if (Array.isArray(this.props.fontNames)) {
      config.fontNames = this.props.fontNames;
    }

    if (Array.isArray(this.props.fontNamesIgnoreCheck)) {
      config.fontNamesIgnoreCheck = this.props.fontNamesIgnoreCheck;
    }

    if (Array.isArray(this.props.fontSizeUnits)) {
      config.fontSizeUnits = this.props.fontSizeUnits;
    }

    if (Array.isArray(this.props.lineHeights)) {
      config.lineHeights = this.props.lineHeights;
    }

    if (!$.summernote.lang[config.lang]) {
      console.error(`Summernote "${config.lang}" lang file must be included.`);
    }

    $.extend(true, config, this.props.config, this._plugins, this._buttons, this._callbacks);

    $(this.$node).summernote(config);

    this._editor = $(this.$node).data('summernote');

    this._editor.layoutInfo.statusbar.find('.note-status-output').remove();

    if (this.props.disabled) {
      this._disable();
    }
  },

  _enable () {
    this._editor.enable();
    this.set('disabled', false);
  },

  _disable () {
    this._editor.disable();
    this.set('disabled', true);
  },

  _empty () {
    this._editor.empty();
  },

  _innerText (str) {
    this._editor.insertText(str);
  },

  _pasteHTML (str) {
    this._editor.pasteHTML(str);
  },

  _undo () {
    this._editor.undo();
  },

  _redo () {
    this._editor.redo();
  },

  _reset () {
    this._editor.reset();
  },

  _setValue (value) {
    this._editor.reset();
    if (value) {
      this._editor.code(value);
    }
    this.set('value', value);
    dmx.nextTick(() => this.dispatchEvent('updated'));
  },

  _initHandler () {
    this.dispatchEvent('init');
  },

  _focusHandler () {
    this._code = this._editor.code();
    this.dispatchEvent('focus');
  },

  _blurHandler () {
    if (this._code !== this._editor.code()) {
      this.dispatchEvent('change');
      dmx.nextTick(() => this.dispatchEvent('changed'));
    }
    this.dispatchEvent('blur');
  },

  _enterHandler () {
    this.dispatchEvent('enter');
  },

  _changeHandler () {
    const value = this._editor.invoke('isEmpty') ? '' : this._editor.code();

    if (this.data.value !== value) {
      this.set('value', value);
      dmx.nextTick(() => this.dispatchEvent('updated'));
    }

    this.dispatchEvent('input');
  },

  _mediaDeleteHandler (target) {
    const src = $(target[0]).attr('src');
    this.dispatchEvent('mediadelete', null, { src });
  },

  _toCamelCase (str) {
    return str.replace(/-(\w)/g, function (a, b) {
      return b.toUpperCase();
    });
  },

  $parseAttributes (node) {
    dmx.BaseComponent.prototype.$parseAttributes.call(this, node);

    this._plugins = {};
    this._buttons = { buttons: {} };

    dmx.dom.getAttributes(node).forEach(attr => {
      if (attr.name == 'plugin') {
        try {
          const plugin = this._toCamelCase(attr.argument);
          this._plugins[plugin] = $.extend({}, $.summernote.options[plugin], dmx.parse(attr.value, this));
        } catch (e) {
          console.error(e);
        }
      }

      if (attr.name == 'button') {
        try {
          const button = this._toCamelCase(attr.argument);
          const { icon, tooltip, click } = dmx.parse(attr.value, this);
          this._buttons.buttons[button] = context => {
            const ui = $.summernote.ui;

            const button = ui.button({
              contents: `<i class="${icon}"/>`,
              tooltip: tooltip || '',
              click: () => {
                if (typeof click == 'string') {
                  dmx.parse(click, this);
                }

                this.dispatchEvent('buttonclick', null, {
                  editor: this.name,
                  button,
                });
              },
            });

            return button.render();
          };
        } catch (e) {
          console.error(e);
        }
      }
    });
  },

  /*



  onBlur: function () {
    if (this.orgValue != this.editor.code()) {
      this.dispatchEvent('change');
      var self = this;
      dmx.nextTick(function () {
        self.dispatchEvent('changed');
      });
    }
    this.dispatchEvent('blur');
  },

  onChange: function () {
    this.updated();
    this.dispatchEvent('input');
  },

  onEnter: function () {
    this.dispatchEvent('enter');
  },

  onFocus: function () {
    this.orgValue = this.editor.code();
    this.dispatchEvent('focus');
  },

  onInit: function () {
    this.dispatchEvent('init');
  },

  onMediaDelete: function (target) {
    var src = $(target[0]).attr('src');
    this.dispatchEvent('mediadelete', null, { src: src });
  },

  toCamelCase: function (str) {
    return str.replace(/-(\w)/g, function (a, b) {
      return b.toUpperCase();
    });
  },

  $parseAttributes: function (node) {
    var self = this;

    dmx.BaseComponent.prototype.$parseAttributes.call(this, node);

    dmx.dom.getAttributes(node).forEach(function (attr) {
      if (attr.name == 'plugin') {
        self.$addBinding(attr.value, function (value) {
          if (value) {
            self.plugins[this.toCamelCase(attr.argument)] = $.extend({}, $.summernote.options[attr.argument], value);
          } else {
            delete self.plugins[attr.argument];
          }

          self.plugins.updated = true;
        });
      }

      if (attr.name == 'button') {
        self.$addBinding(attr.value, function (value) {
          if (value && value.icon) {
            var name = this.toCamelCase(attr.argument);

            self.buttons.buttons[name] = function (context) {
              var ui = $.summernote.ui;

              var button = ui.button({
                contents: '<i class="' + value.icon + '"/>',
                tooltip: value.tooltip || '',
                click: function () {
                  if (typeof value.click == 'string') {
                    dmx.parse(value.click, self);
                  }

                  self.dispatchEvent('buttonclick', null, {
                    editor: self.name,
                    button: name,
                  });
                },
              });

              return button.render();
            };

            self.buttons.updated = true;
          }
        });
      }
    });
  },

  render: function (node) {
    this.$node = node;
  },

  mounted: function () {
    var value = this.$node.tagName == 'TEXTAREA' ? this.$node.value.trim() : this.$node.innerHTML.trim();

    if (value.indexOf('{{') !== -1) {
      this.$addBinding(value, this.setValue);
    }

    this.update({});
  },

  update: function (props) {
    if (this.plugins.updated || this.buttons.updated || !dmx.equal(props, this.props)) {
      delete this.plugins.updated;
      delete this.buttons.updated;

      if (props.value != this.props.value) {
        this.setValue(this.props.value);
      }

      if (this.editor) {
        this.editor.destroy();
        this.editor = null;
      }

      if (!this.isDelayed) {
        this.isDelayed = true;
        dmx.nextTick(function () {
          this.isDelayed = false;
          this.initEditor();
        }, this);
      }
    }
  },

  updated: function () {
    var oldValue = this.data.value;

    if (this.editor) {
      if ($(this.$node).summernote('isEmpty')) {
        this.set('value', '');
      } else {
        this.set('value', this.editor.code());
      }
    } else {
      var value = this.$node.tagName == 'TEXTAREA' ? this.$node.value.trim() : this.$node.innerHTML.trim();
      this.set('value', value);
    }

    if (!this.updating && oldValue != this.data.value) {
      this.isUpdated = true;
      dmx.nextTick(function () {
        this.isUpdated = false;
        this.dispatchEvent('updated');
      }, this);
    }
  },

  beforeDestroy: function () {
    this.editor.destroy();
    this.editor = null;
  },

  setValue: function (value) {
    if (this.editor) {
      this.editor.reset();
      if (value) {
        this.editor.code(value);
      }
    } else if (this.$node.tagName == 'TEXTAREA') {
      this.$node.value = value;
    } else {
      this.$node.innerHTML = value;
    }

    this.updated();
  },

  initEditor: function () {
    var config = dmx.clone(this.config);

    config.height = this.props['height'];
    config.minHeight = this.props['min-height'];
    config.maxHeight = this.props['max-height'];
    config.focus = this.props['autofocus'];
    config.lang = this.props['lang'];
    config.airMode = this.props['air-mode'];
    config.placeholder = this.props['placeholder'];
    config.dialogsInBody = this.props['dialogs-in-body'];
    config.dialogsFade = this.props['dialogs-fade'];
    config.disableDragAndDrop = this.props['disable-drop'];
    config.shortcuts = !this.props['disable-shortcuts'];
    config.tabDisable = this.props['disable-tab'];
    config.spellCheck = !this.props['disable-spellcheck'];
    config.disableGrammar = this.props['disable-grammar'];
    config.blockquoteBreakingLevel = this.props['blockquote-breaking-level'];

    if (Array.isArray(this.props['toolbar'])) {
      // extra filter for empty group generated in Wappler
      config.toolbar = this.props['toolbar'].filter(function (group) {
        return group.length;
      });
    }

    if (Array.isArray(this.props['toolbar-image'])) {
      config.popover = config.popover || {};
      config.popover.image = this.props['toolbar-image'].filter(function (group) {
        return group.length;
      });
    }

    if (Array.isArray(this.props['toolbar-link'])) {
      config.popover = config.popover || {};
      config.popover.link = this.props['toolbar-link'].filter(function (group) {
        return group.length;
      });
    }

    if (Array.isArray(this.props['toolbar-table'])) {
      config.popover = config.popover || {};
      config.popover.table = this.props['toolbar-table'].filter(function (group) {
        return group.length;
      });
    }

    if (Array.isArray(this.props['toolbar-air'])) {
      config.popover = config.popover || {};
      config.popover.air = this.props['toolbar-air'].filter(function (group) {
        return group.length;
      });
    }

    if (Array.isArray(this.props['style-tags'])) {
      config.styleTags = this.props['style-tags'];
    }

    if (Array.isArray(this.props['font-names'])) {
      config.fontNames = this.props['font-names'];
    }

    if (Array.isArray(this.props['font-names-ignore-check'])) {
      config.fontNamesIgnoreCheck = this.props['font-names-ignore-check'];
    }

    if (Array.isArray(this.props['font-size-units'])) {
      config.fontSizeUnits = this.props['font-size-units'];
    }

    if (Array.isArray(this.props['line-heights'])) {
      config.lineHeights = this.props['line-heights'];
    }

    if (!$.summernote.lang[config.lang]) {
      console.error('Summernote "' + config.lang + '" lang file must be included.');
    }

    $.extend(true, config, this.props.config, this.plugins, this.buttons, this.callbacks);

    $(this.$node).summernote(config);

    this.editor = $(this.$node).data('summernote');

    this.editor.layoutInfo.statusbar.find('.note-status-output').remove();

    if (this.props.disabled) {
      this.editor.disable();
      this.set('disabled', true);
    }

    if (this.$node.hasAttribute('dmxDomId')) {
      this.editor.layoutInfo.editor.attr('dmxDomId', this.$node.getAttribute('dmxDomId'));
    }
  },
  */

});
